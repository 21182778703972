import React from "react";

import { EventGiveawayFormBlock as Value } from "@reactivated";

import { HeroPlacement } from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";

import { EventGiveawayForm } from "../CRMForm/EventGiveawayForm";
import { MediaChooserBlock } from "../MediaChooserBlock";
import { RichText } from "../RichText";
import { Split } from "../SplitBlock";

import styles from "./index.module.scss";

export interface Props {
    media: React.JSX.Element | null;
    content: React.JSX.Element | null;
    contentAlignment: Value["content_alignment"];
    imageWidth: Value["image_width"];
    heroPlacement?: HeroPlacement;
    contentClassName?: string;
}

export const EventGiveawayFormBlock = ({ value }: { value: Value }) => {
    return (
        <Split
            contentClassName={styles.contentWrapper}
            content={
                value.content ? (
                    <>
                        <RichText value={value.content} />{" "}
                        <EventGiveawayForm
                            layout="complementary"
                            terms={value.terms}
                        />
                    </>
                ) : null
            }
            media={value.media && <MediaChooserBlock value={value.media} />}
            contentAlignment={value.content_alignment}
            imageWidth={value.image_width}
        />
    );
};
