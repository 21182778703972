import React, { useEffect, useRef, useState } from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import styles from "./index.module.scss";

export interface Props {
    children: React.ReactNode;
    durationInSeconds?: number;
    id?: string | undefined;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export interface CustomCss extends React.CSSProperties {
    "--duration": string;
}

/**
 * A minimally viable marquee/ticker component.
 * - Dumps children into the space
 * - Should be compatible with any kind of marquee length; this can add
 *   (aria-hidden) duplicates depending on how much of the overall width
 *   the text takes up
 */
export const MarqueeBlock = (props: Props) => {
    const root = useRef<HTMLDivElement | null>(null);
    const allTickerText = useRef<HTMLDivElement | null>(null);
    const [duplicates, setDuplicates] = useState(1);

    const attrs = props.attrs || {};
    attrs.className = concatClassNames([attrs.className, styles.root]);

    useEffect(() => {
        const wrapperWidth = root.current?.getBoundingClientRect().width;
        const allTickerWidth =
            allTickerText.current?.getBoundingClientRect().width;
        if (wrapperWidth && allTickerWidth) {
            setDuplicates(Math.ceil(wrapperWidth / allTickerWidth));
        }
    }, []);

    if (!props.children) return <></>;

    return (
        <section
            {...attrs}
            id={props.id}
            style={
                {
                    "--duration": props.durationInSeconds
                        ? `${props.durationInSeconds}s`
                        : "10s",
                } as CustomCss
            }
            ref={root}
        >
            <div className={styles.wrapper} role="marquee">
                <div className={styles.ticker} ref={allTickerText}>
                    {props.children}
                </div>
                {Array(duplicates)
                    .fill(0)
                    .map((_, index) => (
                        <div
                            key={`duplicates${index}`}
                            className={styles.ticker}
                            aria-hidden="true"
                        >
                            {props.children}
                        </div>
                    ))}
            </div>
        </section>
    );
};
