import React from "react";

import { AnimatedCarousel as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { notEmptyOrBlank } from "@thelabnyc/thelabui/src/utils/functional";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { MarqueeBlock } from "../MarqueeBlock";

import styles from "./AnimatedCarousel.module.scss";

type SlideProps = NonNullable<Value["slides"]>[number]["value"];

const ImageSlide = ({ image }: SlideProps) => {
    if (!image) return <></>;

    return (
        <div className={styles.slide}>
            <ImageChooserBlock
                value={image}
                sizes="(max-width: 767px) 64vw, (max-width: 1023px) 28vw, (max-width: 1919px) 15vw, 330px"
                imageAttrs={{ loading: "eager", className: styles.image }}
                attrs={{ className: styles.picture }}
            />
        </div>
    );
};

export const AnimatedCarousel = ({ value: { slides } }: { value: Value }) => {
    if (!slides) return <></>;

    const duration = slides.reduce(
        (accumulator, current) =>
            accumulator + (current.value.image?.width ?? 0),
        0,
    );

    return (
        <MarqueeBlock
            durationInSeconds={duration / 150}
            attrs={{
                className: concatClassNames([
                    styles.carousel,
                    "animatedCarouseStatic",
                ]),
            }}
        >
            {slides.filter(notEmptyOrBlank).map((slide) => (
                <ImageSlide key={slide.id} {...slide.value} />
            ))}
        </MarqueeBlock>
    );
};
