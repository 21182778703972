import { toolboxComponents } from "@client/components/PageSkeleton/Toolbox";
import React from "react";

import { templates } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { EventDetailsBlock } from "../components/EventDetails";
import { EventGiveawayFormBlock } from "../components/EventGiveawayForm";
import { PageSkeleton } from "../components/PageSkeleton";
import { ScrollNavMenuItemBlock } from "../components/ScrollNavMenuBlock";

export const Template = (props: templates.EventLandingPageTemplate) => (
    <PageSkeleton {...props.page}>
        <StreamField
            tagName={React.Fragment}
            value={props.page.body.value}
            components={{
                ...toolboxComponents,
                event_details: (blockProps) => (
                    <EventDetailsBlock {...blockProps} />
                ),
                event_giveaway_form: (blockProps) => (
                    <EventGiveawayFormBlock {...blockProps} />
                ),
                scroll_nav_menu_item: ScrollNavMenuItemBlock,
            }}
        />
    </PageSkeleton>
);
