import React from "react";

import {
    CharBlockOrNull,
    ImageChooserBlockOrNull,
    SKSSocialMediaIconListOrNull,
    EventDetailsBlock as Value,
} from "@reactivated";

import { HeroPlacement } from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { Clickable } from "../Clickables";
import { MediaChooserBlock } from "../MediaChooserBlock";
import { RichText } from "../RichText";
import { Split } from "../SplitBlock";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export interface Props {
    media: React.JSX.Element | null;
    content: React.JSX.Element | null;
    contentAlignment: Value["content_alignment"];
    imageWidth: Value["image_width"];
    heroPlacement?: HeroPlacement;
    contentClassName?: string;
}

interface EventDetailsProps {
    image?: ImageChooserBlockOrNull;
    date?: CharBlockOrNull;
    location?: CharBlockOrNull;
    booth?: CharBlockOrNull;
    icons?: SKSSocialMediaIconListOrNull;
}

export const EventDetails = ({
    image,
    date,
    location,
    booth,
    icons,
}: EventDetailsProps) => (
    <div className={styles.wrapper}>
        {image && (
            <ImageChooserBlock
                value={image}
                attrs={{ className: styles.image }}
            />
        )}
        <dl>
            {date && (
                <>
                    <dt className={styles.label}>Date</dt>
                    <dd className={styles.copy}>{date}</dd>
                </>
            )}
            {location && (
                <>
                    <dt className={styles.label}>Location</dt>
                    <dd className={styles.copy}>{location}</dd>
                </>
            )}
            {booth && (
                <>
                    <dt className={styles.label}>Booth #</dt>
                    <dd className={styles.copy}>{booth}</dd>
                </>
            )}
            {icons && (
                <div>
                    {icons?.map((icon, k) => (
                        <Clickable
                            key={k}
                            className={styles.socialIcon}
                            href={icon.link || undefined}
                        >
                            {icon.icon && <Svg name={icon.icon} />}
                        </Clickable>
                    ))}
                </div>
            )}
        </dl>
    </div>
);

export const EventDetailsBlock = ({ value }: { value: Value }) => {
    return (
        <Split
            contentClassName={styles.contentWrapper}
            content={
                value.content ? (
                    <>
                        <EventDetails
                            image={value.image}
                            date={value.date}
                            location={value.location}
                            booth={value.booth}
                            icons={value.icons}
                        />
                        <RichText value={value.content} />
                    </>
                ) : null
            }
            media={value.media && <MediaChooserBlock value={value.media} />}
            contentAlignment={value.content_alignment}
            imageWidth={value.image_width}
        />
    );
};
