import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import React, { useContext } from "react";
import { t } from "ttag";

import { CharBlockOrNull, Context } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

import { CRMForm, FormType } from ".";
import { ButtonPrimary } from "../Clickables";
import { TextField } from "../Forms/Input";
import { Svg } from "../Svg";
import { FormLayout, Legal } from "./CRMForm";
import { StateSelect } from "./StateSelect";

import styles from "./forms.module.scss";

interface Props extends FormLayout {
    title?: string;
    terms?: CharBlockOrNull;
}

export const EventGiveawayForm = (props: Props) => {
    const context = useContext(Context);
    return (
        <CRMForm
            layout={props.layout}
            type={FormType.EVENT_GIVEAWAY}
            title={props.title}
        >
            {(disabled) => (
                <>
                    <TextField
                        label={t`Email Address`}
                        className={styles.input}
                        name="email_address"
                        isRequired
                        type="email"
                    />
                    <div className={styles.groupedFields}>
                        <TextField
                            label={t`First Name`}
                            className={styles.input}
                            name="first_name"
                            isRequired
                        />
                        <TextField
                            label={t`Last Name`}
                            className={styles.input}
                            name="last_name"
                            isRequired
                        />
                    </div>
                    <TextField
                        label={t`Address`}
                        className={styles.input}
                        name="address"
                        isRequired
                    />
                    <TextField
                        label={t`City`}
                        className={styles.input}
                        name="city"
                        isRequired
                    />
                    <div className={styles.groupedFields}>
                        <StateSelect className={styles.input} />
                        <TextField
                            label={t`ZIP Code`}
                            className={styles.input}
                            name="zip_code"
                            isRequired
                        />
                    </div>
                    <TextField
                        label={t`Phone Number`}
                        className={styles.input}
                        name="phone"
                        isRequired
                    />
                    {props.terms && (
                        <Collapsible className={styles.termsWrapper}>
                            <CollapsibleTrigger className={styles.terms}>
                                Terms and Conditions
                                <Svg
                                    name="caret-right"
                                    className={styles.termsArrow}
                                    aria-hidden="true"
                                />
                            </CollapsibleTrigger>
                            <CollapsibleContent>
                                <RichTextBlock
                                    value={props.terms}
                                    className={styles.termsRichText}
                                />
                            </CollapsibleContent>
                        </Collapsible>
                    )}
                    <Legal
                        href={context.wagtail_links.privacy_policy}
                        className={styles.eventLegalText}
                    />
                    <div className={styles.submitContainer}>
                        <ButtonPrimary disabled={disabled} type="submit">
                            {t`Sign Up`}
                        </ButtonPrimary>
                    </div>
                </>
            )}
        </CRMForm>
    );
};
