import React from "react";

import { FormBlock as Value } from "@reactivated";

import { assertNever } from "@thelabnyc/thelabui/src/utils/never";

import { FormType } from "../CRMForm";
import { ConnectWithDealerForm } from "../CRMForm/ConnectWithDealerForm";
import { ContactForm } from "../CRMForm/ContactForm";
import { EventGiveawayForm } from "../CRMForm/EventGiveawayForm";
import { LGBuilderRegistrationForm } from "../CRMForm/LGBuilderRegistrationForm";
import { RequestAQuoteForm } from "../CRMForm/RequestAQuoteForm";
import { StayInformedForm } from "../CRMForm/StayInformedForm";

interface Props {
    value: Value;
}

export const FormBlock = ({ value }: Props) => {
    if (!value.form) return <></>;
    switch (value.form) {
        case FormType.REQUEST_A_QUOTE:
            return <RequestAQuoteForm layout="solo" />;
        case FormType.STAY_INFORMED:
            return <StayInformedForm layout="solo" />;
        case FormType.CONTACT:
            return <ContactForm />;
        case FormType.LG_BUILDER_REGISTRATION:
            return <LGBuilderRegistrationForm />;
        case FormType.CONNECT_WITH_DEALER:
            return <ConnectWithDealerForm />;
        case FormType.DESIGN_GUIDE_REQUEST_OPT_IN:
            return <></>;
        case FormType.EVENT_GIVEAWAY:
            return <EventGiveawayForm layout="solo" />;
    }
    assertNever(value.form);
};
