import { BlockGroup } from "@client/components/BlockGroup";
import React from "react";

import { Toolbox as Value } from "@reactivated";

import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";

import { AnimatedCarousel } from "../../Carousel/AnimatedCarousel";
import { CenteredHeroBlock } from "../../CenteredHero";
import { ContactSubscribeBlock } from "../../ContactSubscribeBlock";
import { DualImageSplitBlock } from "../../DualImageSplitBlock";
import { FormBlock } from "../../FormBlock";
import { FullWidthImageBlock } from "../../FullWidthImageBlock";
import { GridBlock } from "../../GridBlock";
import {
    ImageBackgroundBlock,
    ImageBackgroundCarouselBlock,
} from "../../ImageBackground";
import { ImageTextSlides } from "../../ImageTextSlides";
import { LocatorMapBlock } from "../../LocatorMapBlock";
import { ModalVideoHeroBlock } from "../../ModalVideoHero";
import { RichTextBlock } from "../../RichTextBlock";
import { SpacerBlock } from "../../SpacerBlock";
import { SplitBlock, SplitCarouselBlock } from "../../SplitBlock";

export const toolboxComponents: StreamFieldBlockMap<Value> = {
    animated_carousel: AnimatedCarousel,
    block_group: BlockGroup,
    centered_hero: CenteredHeroBlock,
    contact_subscribe: ContactSubscribeBlock,
    dual_image_split: DualImageSplitBlock,
    full_width: FullWidthImageBlock,
    form: FormBlock,
    grid: GridBlock,
    image_background: ImageBackgroundBlock,
    image_background_carousel: ImageBackgroundCarouselBlock,
    image_text_slides: ImageTextSlides,
    locator_map: LocatorMapBlock,
    modal_video_hero: ModalVideoHeroBlock,
    rich_text: RichTextBlock,
    spacer: SpacerBlock,
    split: SplitBlock,
    split_carousel: SplitCarouselBlock,
};

export const Toolbox = (props: { value: Value }) => {
    return (
        <StreamField
            tagName={React.Fragment}
            components={toolboxComponents}
            value={props.value}
        />
    );
};
