import { toolboxComponents } from "@client/components/PageSkeleton/Toolbox";
import React from "react";

import { templates } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { PageSkeleton } from "../components/PageSkeleton";

import styles from "../styles/utility-page.module.scss";

export const Template = (props: templates.UtilityPageTemplate) => (
    <PageSkeleton {...props.page} rootClassName={styles.utilityPage}>
        <StreamField
            tagName={React.Fragment}
            value={props.page.body.value}
            components={toolboxComponents}
        />
    </PageSkeleton>
);
